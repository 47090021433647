import React from "react";

const House = ({ color }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.35408 11.6673C3.22769 11.6673 3.12318 11.626 3.04054 11.5434C2.9579 11.4607 2.91658 11.3562 2.91658 11.2298V7.00065H1.73533C1.63811 7.00065 1.57005 6.95447 1.53116 6.86211C1.49227 6.76975 1.51172 6.68954 1.5895 6.62148L6.70825 2.01315C6.78602 1.93537 6.88325 1.89648 6.99991 1.89648C7.11658 1.89648 7.2138 1.93537 7.29158 2.01315L12.4103 6.62148C12.4881 6.68954 12.51 6.76975 12.476 6.86211C12.4419 6.95447 12.3714 7.00065 12.2645 7.00065H11.0832V11.2298C11.0832 11.3562 11.0419 11.4607 10.9593 11.5434C10.8766 11.626 10.7721 11.6673 10.6457 11.6673C10.5194 11.6673 10.4148 11.626 10.3322 11.5434C10.2496 11.4607 10.2082 11.3562 10.2082 11.2298V10.3548H3.79158V11.2298C3.79158 11.3562 3.75026 11.4607 3.66762 11.5434C3.58498 11.626 3.48047 11.6673 3.35408 11.6673ZM4.89991 4.81315H9.09991L6.99991 2.9319L4.89991 4.81315ZM3.79158 7.14648H10.2082V5.80482L10.077 5.68815H3.92283L3.79158 5.80482V7.14648ZM3.79158 9.47982H10.2082V8.02148H3.79158V9.47982Z" fill="#333333"/>
    </svg>
  );
};
export default House;
